export default {
  "CHANGE LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHANGE LANGUAGE"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
  "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGNUP"])},
  "SHOPPING CART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOPPING CART"])},
  "TRACK MY ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRACK MY ORDER"])},
  "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Flash Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Sale"])},
  "SELL WELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELL WELL"])},
  "CLASSIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLASSIFICATION"])},
  "Amount to pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
  "Shopping Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart"])},
  "Product information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product information"])},
  "Unit Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "Service Charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Freight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freight"])},
  "Check Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
  "PUSH TO YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUSH TO YOU"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "All Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Orders"])},
  "Return Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Orders"])},
  "Refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunding"])},
  "Refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
  "To Ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Ship"])},
  "To Receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Receive"])},
  "To Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Review"])},
  "Return and Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return and Exchange"])},
  "Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
  "Available Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Coupons"])},
  "Invalid Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Coupons"])},
  "Account Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
  "Basic Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "My Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Attention"])},
  "Help Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
  "View All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
  "The goods you purchased have been picked up and are waiting for delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["he goods you purchased have been picked up and are waiting for delivery"])},
  "View Logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Logistics"])},
  "Receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "Order Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
  "Order Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
  "Related Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Categories"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "Product Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Features"])},
  "Popular Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Brands"])},
  "Colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors"])},
  "Current Deals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Deals"])},
  "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
  "Results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results found"])},
  "BestMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Match"])},
  "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
  "Price Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Range"])},
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW"])},
  "HOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOT"])},
  "Filtered By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered By"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "results were found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results were found"])},
  "Clear All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All"])},
  "people buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["people buy"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Pages Go to Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages Go to Page"])},
  "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO"])},
  "Free Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
  "Cash On Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash On Delivery"])},
  "Min Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Price"])},
  "Max Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Price"])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "Star Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Rating"])},
  "User feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User feedback"])},
  "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
  "FLASH SALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLASH SALE"])},
  "Distance End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance End"])},
  "Buy Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
  "Add to Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Cart"])},
  "Add to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Wishlist"])},
  "Added to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to Wishlist"])},
  "Share product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share product"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
  "Available Payment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Payment Method"])},
  "Minimum Shipping Fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Shipping Fee"])},
  "Cash on Delivery Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash on Delivery Available"])},
  "Home Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Delivery"])},
  "Return & Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return & Warranty"])},
  "7 days easy return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 days easy return"])},
  "Change of mind is not applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of mind is not applicable"])},
  "100% Authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Authentic"])},
  "Products Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Sold"])},
  "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "Add a review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a review"])},
  "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
  "OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OVERVIEW"])},
  "CUSTOMER REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER REVIEWS"])},
  "All Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Evaluation"])},
  "Slide Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide Show"])},
  "Praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praise"])},
  "Middle evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle evaluation"])},
  "Negative Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative Comment"])},
  "Added successfully!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added successfully!"])},
  "Please select the product variant！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the product variant！"])},
  "Search For Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search For Products"])},
  "PANIC BUYING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PANIC BUYING"])},
  "The": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
  "We are sorry, We cannot find any product for your searched category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, We cannot find any product for your searched category"])},
  "Popular Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Search"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "Please select payment method！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select payment method！"])},
  "支付成功!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment succeeded!"])},
  "您的订单支付成功": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order was paid successfully"])},
  "Unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
  "Shipping address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])},
  "New shipping address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New shipping address"])},
  "Edit shipping address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit shipping address"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "Full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
  "minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minus"])},
  "Order Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
  "The total amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total amount"])},
  "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "Shipping fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Fee"])},
  "Amount payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount payable"])},
  "Order Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Place"])},
  "Agree to our company’s privacy policy and conditions of use, place an order immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to our company’s privacy policy and conditions of use, place an order immediately"])},
  "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Set as default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default"])},
  "Are you sure you want to remove this product?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this product?"])},
  "This product has reached the maximum purchaseable quantity!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product has reached the maximum purchaseable quantity!"])},
  "This is the minimum order quantity!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the minimum order quantity!"])},
  "Please select the product first!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the product first!"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "Product Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Score"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "Evaluation Sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Sheet"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "File size does not exceed 3M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size does not exceed 3M"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Application has been submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application has been submitted"])},
  "Confirm application refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm application refund"])},
  "After Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After Sale"])},
  "Refund Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Type"])},
  "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
  "REFUND&RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND&RETURN"])},
  "Submitted Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted Quantity"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "Confirm Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Submission"])},
  "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ToPay"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Confirm Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Receipt"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
  "Order Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirm"])},
  "Order Dispatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Dispatched"])},
  "Waiting for Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for Receipt"])},
  "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
  "Consignee Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignee Information"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "Customer Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
  "Payment Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
  "paymentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PaymentId"])},
  "transactionID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction ID"])},
  "Commodity Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commodity Number"])},
  "Head Portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head Portrait"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "secrecy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrecy"])},
  "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
  "New Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Shipping Address"])},
  "Original Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Password"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "Refund Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Number"])},
  "Order Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Main"])},
  "Applying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applying"])},
  "Agree to apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to apply"])},
  "Refuse to apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse to apply"])},
  "Agree to a refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to a refund"])},
  "Ticket no.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket no."])},
  "Valid time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid time"])},
  "USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USE"])},
  "spendAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spendAmount"])},
  "Email Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Login"])},
  "Register Now >": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now >"])},
  "l agree to the ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the "])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "Get Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Code"])},
  "Delete completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete completion"])},
  "Cart is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart is empty"])},
  "Continue Shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Shopping"])},
  "has been generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been generated"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "You can pay in cash to our courier when you receivethe goods at your doorstep.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can pay in cash to our courier when you receivethe goods at your doorstep."])},
  "Confirm Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order"])},
  "请输入手机号码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile phone number!"])},
  "请输入正确的手机号码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the correct mobile phone number!"])},
  "请输入邮箱！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email!"])},
  "请输入正确的邮箱！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the correct email address!"])},
  "请输入验证码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code!"])},
  "登录前请同意本服务条款及隐私政策！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to the Terms of Service and Privacy Policy before Sign-in!"])},
  "修改成功！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save successfully!"])},
  "姓名请勿超出32字符！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name should not exceed 32 characters!"])},
  "Order Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
  "To pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pay"])},
  "To Pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Pay"])},
  "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classification"])},
  "No reviews yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviews yet"])},
  "商品已下架！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The goods are off the shelf!"])},
  "暂无数据！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data yet!"])},
  "已下架": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["off shelve"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of stock"])},
  "Product details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details"])},
  "Product Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Information"])},
  "正在加载中，请稍后~": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading, please wait~"])},
  "提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips!"])},
  "是否支付？": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to confirm the order?"])},
  "fold the sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fold the sale"])},
  "No More Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Coupon"])},
  "Amount of real pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of real pay"])},
  "商品将从购物车中删除": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item(s) will be removed from cart"])},
  "确认删除选中商品？": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the selected item(s)?"])},
  "Coupons History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons History"])},
  "请输入原因！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the reason!"])},
  "Please don't repeat apply for refund!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please don't repeat apply for refund!"])},
  "All products available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products available"])},
  "Some products available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some products available"])},
  "Some products unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some products unavailable"])},
  "Above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above"])},
  "Min.spend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Spend"])},
  "No limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No limit"])},
  "此商品已下架！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product has been taken off the shelf!"])},
  "此属性商品库存不足！请刷新页面": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inventory of goods with this attribute is insufficient! Please refresh the page"])},
  "After sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After sales"])},
  "After sales order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After sales order"])},
  "Application Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Number"])},
  "Order information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order information"])},
  "Refund Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Information"])},
  "Application Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Time"])},
  "Application Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Amount"])},
  "Reason for refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for refund"])},
  "Refund Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Amount"])},
  "您已领取了1张券": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Recieved 1 Coupon"])},
  "您已领取了": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Recieved"])},
  "张券": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
  "已达到上限，不可领取！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upper limit has been reached and cannot be recieved"])},
  "Specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
  "My favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Favorites"])},
  "Do you want to use this shipping address?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to use this shipping address?"])},
  "Notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
  "Remove from cart!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from cart!"])},
  "删除提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Item(s)"])},
  "确认提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order"])},
  "当前界面缺少参数!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current interface is missing parameters!"])},
  "Order Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirmed"])},
  "has been confirmed.You can do the payment when you receive the product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been confirmed.You can do the payment when you receive the product."])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "请填写所有选项!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all the information!"])},
  "Clicking the above button means you agree to our company's Privacy policy and Terms & Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking the above button means you agree to our company's Privacy policy and Terms & Conditions"])},
  "您已领取了此券.赶快去使用吧!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collected Successfully. Use it!"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "Share your experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your experience"])},
  "Confirm ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "Express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express"])},
  "Review ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review "])},
  "Do you confirm that you received the product?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm that you received the product?"])},
  "Add Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Review"])},
  "文件大小不超过3M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size limit is 3MB"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Remove Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Address"])},
  "Save Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Successfully"])},
  "COUPON COLLECTION CENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COUPON COLLECTION CENTER"])},
  "Please enter valid name!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid name!"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "common": {
    "productStatus": {
      "soldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out!"])}
    }
  },
  "order": {
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "ORDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDERS"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
    "MyOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Order"])},
    "MostPopular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Popular"])},
    "PaymentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Selection"])},
    "PriceLowToHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Low to High"])},
    "PriceHighToLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price High to Low"])}
  },
  "freight": {
    "FreeFreight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])}
  },
  "pay": {
    "successful": {
      "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No"])},
      "OrderConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirmed"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been confirmed."])},
      "cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can do the payment when you receive the product."])},
      "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please transfer payment to the following account."])}
    }
  },
  "heier": {
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking the above button means you agree to our company's Privacypolicy and Terms & Conditions. "])},
    "bankAccount": {
      "AccountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
      "NameOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Account"])},
      "BankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
      "AccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
      "AccountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
      "IBANNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Number"])},
      "Branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "BankAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Address"])},
      "OrderValidityTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Validity Time"])},
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload the transfer receipt before the end of time"])}
    }
  },
  "user": {
    "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
    "HOMEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME PAGE"])},
    "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
    "Kefu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])}
  },
  "account": {
    "PWSuc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password set successfully"])}
  }
}