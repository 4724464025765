<script setup>
  import defaultImage from '@/assets/imgs/productDefault.png'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import JLTools from '@/util/JLTools'

  const $emit = defineEmits(['click'])

  const props = defineProps({
    src: {
      type: [String, Array]
    },
    quality: {
      type: String,
      default: '75'
    },
    fit: {
      type: String
    },
    lazy: {
      type: Boolean,
      default: true
    }
  })

  watch(
    () => props.src,
    (newValue, oldValue) => {
      // console.log('更新图片')
      formatUrl()
    }
  )
  const fitValue = ref(props.fit)
  const url = ref('')

  const formatUrl = () => {
    let value = props.src
    if (JLTools.isNull(value)) return
    if (typeof value === 'string' && value.indexOf('data:image/png') !== 0) {
      value = value.split(',')[0]
    } else if (Array.isArray(value)) {
      value = value[0]
    }
    if (value) {
      if (String(value).indexOf('http') == -1) {
        url.value = `${process.env.VUE_APP_IMG_URL}${value}?imageView2/0/format/webp/q/${props.quality}`
      } else {
        url.value = `${value}?imageView2/0/format/webp/q/${props.quality}`
      }
    }
  }

  const error = () => {
    url.value = defaultImage,
    fitValue.value = 'contain'
  }

  const imgClick = ($event) => {
    $emit('click', $event)
  }

  onMounted(() => {
    // console.log('初始化')
    formatUrl()
  })
</script>

<template>
  <el-image :src="url" :fit="fitValue" @error="error" @click="imgClick" :lazy="props.lazy">
    <div slot="placeholder">
      <el-skeleton-item variant="image" style="width: 100%; height: 100%" />
    </div>
  </el-image>
</template>

<style lang="less" scoped></style>
