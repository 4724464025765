<template>
  <div class="message" v-show="isOpen">
    <div class="component address">
      <div class="component_header">
        <div class="ico-box rowCC">
          <img v-if="type === 'succeed'" class="message-ico" src="@/assets/imgs/common/succeed.png" />
          <img v-else-if="type === 'error'" class="message-ico" src="@/assets/imgs/common/error.png" />
          <img v-else class="message-ico" src="@/assets/imgs/common/warning.png" />
        </div>
        <p class="title">{{ title }}</p>
      </div>
      <div class="component_message" v-html="msg"></div>
      <div class="component_footer rowCC">
        <button class="btn btn-cancel mr10" v-if="isCancel" @click="close">{{ cancelText }}</button>
        <button class="btn btn-ok" @click="ok">{{ okText }}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import { reactive, defineComponent, toRefs, getCurrentInstance } from 'vue'

  export default defineComponent({
    props: {
      type: {
        type: String,
        default: 'warning'
      },
      title: {
        type: String,
        default: 'this is Title'
      },
      msg: {
        type: String,
        default: 'this is Message'
      },
      isOpen: {
        type: Boolean,
        default: false
      },
      cancelText: {
        type: String,
        default: 'Cancel'
      },
      isCancel: {
        type: Boolean,
        default: true
      },
      okText: {
        type: String,
        default: 'OK'
      }
    },
    setup(props, context) {
      const data = reactive({
        confirmFlag: null
      })
      const { proxy } = getCurrentInstance()
      const { remove } = proxy.$attrs

      const ok = () => {
        remove()
        context.emit('onOk')
      }

      const close = () => {
        remove()
        context.emit('onCancel')
      }
      return {
        ...toRefs(data),
        confirm,
        ok,
        close
      }
    }
  })
</script>
<style lang="less" scoped>
  .message {
    position: fixed;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2099;
    .component {
      min-width: 420px;
      min-height: 80px;
      padding: 25px 40px;
      background: #fff;
      margin: calc(50vh - 180px) calc(50vw - 210px) 0;
      box-shadow: 0 0 15px #aaa;
      border-radius: 10px;
    }
    .component_header {
      width: 100%;
      .title {
        color: #363636;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    .component_message {
      width: 100%;
      min-height: 50px;
      margin-bottom: 10px;
      font-size: 14px;
      text-align: center;
      color: var(--neutrals-80, #515b6f);
    }
    .component_footer {
      width: 100%;
      .btn {
        width: 100px;
        height: 36px;
        border-radius: 34px;
        font-size: 16px;
        font-weight: 500;
        padding: unset;
        box-sizing: border-box;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .btn-cancel {
        border: 2px solid #222;
        background: #fff;
        color: #222;
      }
      .btn-ok {
        height: 40px;
        background-color: var(--btnBgColor);
        color: #fff;
      }
    }
    .ico-box {
      width: 100%;
      margin-bottom: 20px;
      .message-ico {
        width: 60px;
        height: 60px;
      }
    }
  }
</style>
