// 其他路由配置
const otherRoutes = [
  {
    path: '/other',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error/404.vue'),
        meta: {
          cnName: '页面不存在',
          enName: 'Page Not Found'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },

  {
    path: '/OCR',
    name: 'OCR',
    component: () => import('@/views/ocr/index.vue'),
    meta: {
      cnName: 'OCR页面',
      enName: 'OCR Page'
    }
  }
]

export default otherRoutes
