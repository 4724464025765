import axios from 'axios'
import { Aes } from '@/util/aes'
import qs from 'qs'
import md5 from 'js-md5'
import router from '@/router'
import { ElNotification } from 'element-plus'

const aes = new Aes()

let config = {
  // baseURL: process.env.VUE_APP_API_DOMAIN_OLD
  baseURL: process.env.VUE_APP_API_DOMAIN,
  headers: { clientType: 'haierMall' }
}
const _axios = axios.create(config)

function setNonce(len) {
  len = len || 32
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

// 根据请求生成对应的key
const generateReqKey = (config, hash) => {
  const { method, url, params, data } = config
  return [method, url, JSON.stringify(params), JSON.stringify(data), hash].join('&')
}

// 存储已发送但是没有响应的请求
const pendingRequest = new Set()

// 添加请求拦截器
_axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = localStorage.getItem(`${process.env.NODE_ENV}_token`) || ''
    const nonce = setNonce(32)
    const timestamp = new Date().getTime()
    const key = 'rh5ffurhv28m2q14'
    // config.headers = { 'Content-Type': 'application/json;charset=UTF-8' }
    config.headers.Nonce = nonce
    config.headers.Timestamp = timestamp

    if (token) {
      const param = `Authorization=Bearer ${token}&Nonce=${nonce}&Timestamp=${timestamp}&Key=${key}`
      config.headers.Sign = md5(param).toUpperCase()
      config.headers.Authorization = `Bearer ${token}`
    } else {
      const param = `Nonce=${nonce}&Timestamp=${timestamp}&Key=${key}`
      config.headers.Sign = md5(param).toUpperCase()
    }

    let hash = location.hash
    // 生成请求key
    let reqKey = generateReqKey(config, hash)
    if (pendingRequest.has(reqKey)) {
      return Promise.reject({
        message: '请求重复'
      })
    }
    // 将请求的key保存在config
    config.pendKey = reqKey
    pendingRequest.add(reqKey)

    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

/**
 * post方法， JSON格式提交
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const post = function (url, data, options) {
  const doEncrypt = options?.isDoEncrypt ? false : true
  return new Promise((resolve, reject) => {
    let deData
    if (typeof data === 'string') {
      deData = data
    } else {
      deData = JSON.stringify(data)
    }
    const parms = doEncrypt ? aes.doEncrypt(deData) : deData
    _axios
      .post(url, parms, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          accept: '*/*',
          zongVersion: 1
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * post方法， form表单格式提交
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const post_form = function (url, data) {
  console.log(data, '312321')
  return new Promise((resolve, reject) => {
    _axios
      .post(url, qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          accept: '*/*',
          zongVersion: 1
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/** get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param { pageSize: 是否返回header } headers [请求时携带的参数]
 */
const get = function (url, data, headers) {
  // console.log(headers, 'headers=GET')
  let params = {}
  for (let key in data) {
    let value = data[key]
    if (typeof value === 'object') value = JSON.stringify(value)
    params[key] = value
  }

  return new Promise((resolve, reject) => {
    let getUrl = JSON.stringify(params) == '{}' ? url : url + '?' + qs.stringify(params)
    // if (!this.JLTools.isNull(data.repeat)){
    //     getUrl = getUrl + data.repeat
    // }
    _axios
      .get(getUrl, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          zongVersion: 1
        }
      })
      .then((res) => {
        if (headers?.pageSize) {
          return resolve(res)
        }
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/** del方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
const del = function (url, data) {
  let deData
  if (typeof data === 'string') {
    deData = data
  } else {
    deData = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    _axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: aes.doEncrypt(deData)
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/** put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
const put = function (url, data) {
  let deData
  if (typeof data === 'string') {
    deData = data
  } else {
    deData = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    _axios
      .put(url, aes.doEncrypt(deData), {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/** PATCH方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
const patch = function (url, data) {
  return new Promise((resolve, reject) => {
    let deData
    if (typeof data === 'string') {
      deData = data
    } else {
      deData = JSON.stringify(data)
    }
    _axios
      .patch(url, aes.doEncrypt(deData), {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          accept: '*/*'
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * post方法， 提交文件,无加密
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const post_file = function (url, data) {
  return new Promise((resolve, reject) => {
    _axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          accept: '*/*',
          zongVersion: 1
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

// 响应拦截器
_axios.interceptors.response.use(
  (response) => {
    // console.log(response, 'response.data')
    if (response.config.pendKey) {
      pendingRequest.delete(response.config.pendKey)
    }
    let deResponse = '' || []
    if (response.data && response.data.length > 1) {
      deResponse = JSON.parse(aes.doDecrypt(response.data))
    } else {
      deResponse = response.data
    }
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 如果返回的状态码为201，说明创建成功状态码
    // 如果返回的状态码为204，请求执行成功，但是没有数据，浏览器不用刷新页面，也不用导向新的页面
    // 否则的话抛出错误
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      // console.log('成功')
      return Promise.resolve({
        data: deResponse,
        headers: response.headers
      })
    }
    return Promise.reject(response)
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    if (error.config && error.config.pendKey) {
      pendingRequest.delete(error.config.pendKey)
    }
    if (error?.response?.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          // // console.log('dsdsd   ',error.response.data.error)
          if (error.response.data.error === 'invalid_token') {
            localStorage.clear()
          }
          router.push({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break

        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token
        // 跳转登录页面
        case 403:
          // 清除token
          localStorage.clear()
          localStorage.removeItem('userInfo')
          localStorage.removeItem(`${process.env.NODE_ENV}_token`)
          //  跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.push({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath,
                skipType: error.response?.data?.code === 912 || error.response?.data?.code === 913 ? 'anew' : ''
              }
            })
            if (error.response?.data?.code === 912 || error.response?.data?.code === 913) {
              // 重复登录
              console.log(error.response?.data?.code, '重复登录')
              openElNotification(error.response?.data?.message, 'warning', true)
            }
          }, 1000)
          break

        // 404请求不存在
        case 404:
          // console.log("网络请求不存在");
          break
        // 其他错误，直接抛出错误提示
        default:
        // console.log(error.response.data.message);
      }
      return Promise.reject(error.response)
    }
    console.log(error, 'error')
    return Promise.reject(error)
  }
)

let notificationVisible = false // 标志用于控制通知是否已经显示
const openElNotification = async (msg, type, isRestrict) => {
  if (isRestrict && notificationVisible) return
  notificationVisible = true
  ElNotification({
    title: type.charAt(0).toUpperCase() + type.slice(1),
    message: `${msg}`,
    type: `${type}`,
    onClose: () => {
      notificationVisible = false // 在通知关闭时将标志设置回未显示
    }
  })
}

export default {
  post,
  get,
  patch,
  del,
  put,
  post_form,
  post_file
}
