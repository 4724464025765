import api from '@/api/request'

// 增加返现记录
export function addCashback(params) {
  return api.post(`${process.env.VUE_APP_HAIERTOOLS_API}api/v1/wallet/cashback/store`, params, {
    isDoEncrypt: true
  })
}

// 编辑返现记录
export function emitCashback(params, requestId) {
  return api.post(`${process.env.VUE_APP_HAIERTOOLS_API}api/v1/wallet/cashback/${requestId}/store`, params, {
    isDoEncrypt: true
  })
}

// 验证安装编号是否提交
export function validateIrn(params) {
  return api.post(`${process.env.VUE_APP_HAIERTOOLS_API}api/v1/wallet/cashback/validate/irn`, params, {
    isDoEncrypt: true
  })
}

// 返现记录list
export function getCashbackList(params) {
  return api.post(`${process.env.VUE_APP_HAIERTOOLS_API}api/v1/wallet/cashback/request/user`, params, {
  // return api.get(`${process.env.VUE_APP_HAIERTOOLS_API}api/v1/wallet/cashback/get-all`, params, {
    isDoEncrypt: true
  })
}