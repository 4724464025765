// 登录相关
const homeRoutes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/index/index.vue'),
        meta: {
          cnName: '首页',
          enName: 'Home'
        }
      },
      {
        path: '/news1',
        name: 'News1',
        component: () => import('@/views/news/News1.vue'),
        meta: {
          cnName: '新闻1',
          enName: 'News 1'
        }
      },
      {
        path: '/news2',
        name: 'News2',
        component: () => import('@/views/news/News2.vue'),
        meta: {
          cnName: '新闻2',
          enName: 'News 2'
        }
      }
    ]
  }
  // 其他首页的路由配置
]

export default homeRoutes
