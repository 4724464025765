// 订单支付相关
const orderPayRouter = [
  {
    path: '/order',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/settlement',
        name: 'SETTLEMENT',
        component: () => import('@/views/settlement/index.vue'),
        meta: {
          cnName: '结算',
          enName: 'Settlement'
        }
      },

      {
        path: '/orderMain/:orderId',
        name: 'ORDERMAIN',
        component: () => import('@/views/order/orderMain/index.vue'),
        meta: {
          cnName: '订单详情',
          enName: 'Order Detail'
        }
      },
      {
        path: '/orderEvaluate',
        name: 'ORDEREVALUATE',
        component: () => import('@/views/order/orderEvaluate/index.vue'),
        meta: {
          cnName: '已收货评论',
          enName: 'Received Comments'
        }
      },
      {
        path: '/afterSale/:order',
        name: 'AFTERSALE',
        component: () => import('@/views/order/afterSale.vue'),
        meta: {
          cnName: '售后服务',
          enName: 'After-sale Service'
        }
      },

      {
        path: '/successful',
        name: 'successful',
        component: () => import('@/views/pay/successful/index.vue'),
        meta: {
          cnName: '支付成功',
          enName: 'Payment Successful'
        }
      },
      {
        path: '/other-payments',
        name: 'OTHERPAYMENT',
        component: () => import('@/views/pay/other-payments.vue'),
        meta: {
          cnName: '其他支付方式',
          enName: 'Other Payments'
        }
      }
    ]
  }
]

export default orderPayRouter
