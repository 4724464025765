// 用户中心相关
const userInfoRoutes = [
  {
    path: '/userinfo',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/userinfo',
        name: 'USERINFO',
        component: () => import('@/views/userinfo/index.vue'),
        meta: {
          cnName: '用户信息',
          enName: 'User Info'
        }
      },
      {
        path: '/couponIndex',
        name: 'COUPONINDEX',
        component: () => import('@/views/userinfo/coupon/CouponIndex.vue'),
        meta: {
          cnName: '优惠券',
          enName: 'Coupon'
        }
      },
      {
        path: '/userwallet',
        name: 'Wallet',
        component: () => import('@/views/userinfo/userwallet/Index.vue'),
        meta: {
          cnName: '用户钱包',
          enName: 'User Wallet'
        }
      },
      {
        path: '/withdraw',
        name: 'WithDraw',
        component: () => import('@/views/userinfo/userwallet/withdraw.vue'),
        meta: {
          cnName: '提现',
          enName: 'Withdraw'
        }
      }
    ]
  }
]

export default userInfoRoutes
