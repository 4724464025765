import api from '@/api/request'

// 首页定制接口
export function getCustomPages(data) {
  return api.get('/services/common/api/custom-pages', data)
}

// 图片上传
export function uploadsFile(data) {
  return api.post_file('/services/order/api/uploads/file', data)
}

// 获取外部活动
export function getCampaigns(data) {
  return api.get('/services/common/api/find/external/campaigns')
}