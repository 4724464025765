// Promise 工具函数
export const flux = {
  reject: Promise.reject.bind(Promise),
  resolve: Promise.resolve.bind(Promise),
  all: Promise.resolve.bind(Promise)
}

// 防抖函数
export const debounce = (func, delay = 300) => {
  let timer = null
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      console.log('触发防抖')
      func(...args)
      timer = null
    }, delay)
  }
}

// 节流函数
export const throttle = (func, delay = 300) => {
  let timer = null
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        func(...args)
        timer = null
      }, delay)
    }
  }
}

// 数组对象去重
export const formatArr = (arr) => {
  const map = new Map()
  for (const item of arr) {
    if (!map.has(item.city)) {
      map.set(item.city, item)
    }
  }
  return [...map.values()]
}

// 数组对象属性去重
export const addToArrWithLatestProperty = (arr, newObj, propertyName) => {
  const index = arr.findIndex((item) => item[propertyName] === newObj[propertyName])
  if (index !== -1) {
    arr.splice(index, 1, newObj)
  } else {
    arr.push(newObj)
  }
  return arr
}

// 根据属性查找数组对象
export const findByProperty = (arr, propertyName, propertyValue) => arr.find((item) => item[propertyName] === propertyValue)

// 检查两个数组是否有相同元素
export const haveCommonElements = (array1, array2) => {
  for (let i = 0; i < array1.length; i++) {
    if (array2.some((item) => item === array1[i])) {
      return true
    }
  }
  return false
}

// 检查两个数组是否有相同元素  如果有返回第一个数组相同元素的下标
export const findIndexOfCommonElement = (array1, array2) => {
  // console.log(array1, array2, 3333)
  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(String(array1[i]))) {
      return i // 返回第一个数组相同元素的下标
    }
  }
  return -1 // 如果没有相同元素，返回 -1
}

// 帮助函数：合并对象属性
export const mergeProperties = (target, source) => {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      target[key] = source[key]
    }
  }
}

export const urlCompletion = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    // 获取当前网站的地址
    var currentUrl = window.location.href
    // 提取当前网站的域名部分
    var baseURL = currentUrl.split('/')[2]

    // 判断是否以斜杠开头
    if (url.startsWith('/')) {
      url = 'http://' + baseURL + url
    } else {
      url = 'http://' + baseURL + '/' + url
    }
  }

  return url
}

export const strTitleCase = (str) => {
  if (!str) return ''
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

export const getEnv = () => {
  return process.env.NODE_ENV
}
